/* line 4, ../../../scss/sports.scss */
html {
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

/* line 9, ../../../scss/sports.scss */
body {
  padding: 0px;
  margin: 0px;
  background-color: #FFF;
  text-align: center;
  color: #000;
}
/* line 16, ../../../scss/sports.scss */
body.hide-scrolling {
  overflow: hidden;
}

/* line 21, ../../../scss/sports.scss */
.content {
  margin: 1rem;
  font-size: 0.9rem;
}
/* line 25, ../../../scss/sports.scss */
.content .lists {
  border: 0.15rem solid #162955;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 10fr;
}
/* line 31, ../../../scss/sports.scss */
.content .lists .title {
  font-size: 1.1rem;
  background-color: #162955;
  color: #FFF;
  text-align: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* line 43, ../../../scss/sports.scss */
.content .lists .title small {
  font-size: 0.9rem;
}
/* line 48, ../../../scss/sports.scss */
.content .lists .data-grid {
  display: grid;
  grid-gap: 0px;
  border-top: 0.15rem solid #AA818D;
}
/* line 53, ../../../scss/sports.scss */
.content .lists .data-grid .box {
  border-right: 0.15rem solid #AA818D;
  text-align: center;
  line-height: 1.8rem;
}
/* line 58, ../../../scss/sports.scss */
.content .lists .data-grid .box.top-tag {
  font-weight: bold;
}
/* line 62, ../../../scss/sports.scss */
.content .lists .data-grid .box.filter a {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
/* line 67, ../../../scss/sports.scss */
.content .lists .data-grid .box.filter a:hover {
  font-weight: bold;
}
/* line 73, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 {
  grid-template-columns: repeat(6, 1fr);
}
/* line 76, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box:nth-child(6), .content .lists .data-grid.cols-6 .box:nth-child(6n) {
  border-right: none;
}
/* line 80, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box:nth-child(12n+1), .content .lists .data-grid.cols-6 .box:nth-child(12n+2), .content .lists .data-grid.cols-6 .box:nth-child(12n+3), .content .lists .data-grid.cols-6 .box:nth-child(12n+4), .content .lists .data-grid.cols-6 .box:nth-child(12n+5), .content .lists .data-grid.cols-6 .box:nth-child(12n+6) {
  background-color: #E6E6E6;
}
/* line 85, ../../../scss/sports.scss */
.content .lists .data-grid.cols-7 {
  grid-template-columns: repeat(7, 1fr);
}
/* line 88, ../../../scss/sports.scss */
.content .lists .data-grid.cols-7 .box:nth-child(6), .content .lists .data-grid.cols-7 .box:nth-child(6n) {
  border-right: 0.15rem solid #AA818D;
}
/* line 92, ../../../scss/sports.scss */
.content .lists .data-grid.cols-7 .box:nth-child(12n+1), .content .lists .data-grid.cols-7 .box:nth-child(12n+2), .content .lists .data-grid.cols-7 .box:nth-child(12n+3), .content .lists .data-grid.cols-7 .box:nth-child(12n+4), .content .lists .data-grid.cols-7 .box:nth-child(12n+5), .content .lists .data-grid.cols-7 .box:nth-child(12n+6) {
  background-color: white;
}
/* line 96, ../../../scss/sports.scss */
.content .lists .data-grid.cols-7 .box:nth-child(14n+1), .content .lists .data-grid.cols-7 .box:nth-child(14n+2), .content .lists .data-grid.cols-7 .box:nth-child(14n+3), .content .lists .data-grid.cols-7 .box:nth-child(14n+4), .content .lists .data-grid.cols-7 .box:nth-child(14n+5), .content .lists .data-grid.cols-7 .box:nth-child(14n+6), .content .lists .data-grid.cols-7 .box:nth-child(14n+7) {
  background-color: #E6E6E6;
}
/* line 100, ../../../scss/sports.scss */
.content .lists .data-grid.cols-7 .box:nth-child(7), .content .lists .data-grid.cols-7 .box:nth-child(7n) {
  border-right: none;
}
/* line 105, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box.order-col, .content .lists .data-grid.cols-7 .box.order-col {
  background-color: #96A0B8;
  color: #FFF;
  font-weight: bold;
  position: relative;
}
/* line 111, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box.order-col .order-by, .content .lists .data-grid.cols-7 .box.order-col .order-by {
  position: absolute;
  top: 0;
  right: 1rem;
}
/* line 116, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box.order-col .order-by a, .content .lists .data-grid.cols-7 .box.order-col .order-by a {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}
/* line 121, ../../../scss/sports.scss */
.content .lists .data-grid.cols-6 .box.order-col .order-by a:hover, .content .lists .data-grid.cols-7 .box.order-col .order-by a:hover {
  color: #4F202D;
}
/* line 129, ../../../scss/sports.scss */
.content .lists .stats-tag .box {
  font-weight: bold;
}
/* line 134, ../../../scss/sports.scss */
.content .tags {
  display: inline-block;
  text-decoration: none;
  padding: 0.2rem 0.9rem 0.3rem;
  margin: 0 0.2rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1rem;
  color: #FFF;
  background-color: #485370;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
}
/* line 150, ../../../scss/sports.scss */
.content .show-all {
  display: inline;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  margin: 0 0.2rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1rem;
  color: #485370;
  background-color: #FFF;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  cursor: pointer;
}
/* line 167, ../../../scss/sports.scss */
.content .title-filter {
  font-size: 1.1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #162955;
  color: #FFF;
  text-transform: uppercase;
}
/* line 177, ../../../scss/sports.scss */
.content .title-filter .show-all {
  margin-left: 1rem;
}

@media (max-width: 1000px) {
  /* line 185, ../../../scss/sports.scss */
  .content .lists {
    grid-template-columns: 1fr;
  }
  /* line 188, ../../../scss/sports.scss */
  .content .lists .title {
    flex-direction: row;
  }
  /* line 191, ../../../scss/sports.scss */
  .content .lists .title small {
    padding-left: 1rem;
  }
  /* line 197, ../../../scss/sports.scss */
  .content .lists .data-grid.cols-6 {
    grid-template-columns: repeat(3, 1fr);
  }
  /* line 200, ../../../scss/sports.scss */
  .content .lists .data-grid.cols-6 .box:nth-child(3), .content .lists .data-grid.cols-6 .box:nth-child(3n) {
    border-right: none;
  }
  /* line 205, ../../../scss/sports.scss */
  .content .lists .data-grid.cols-7 {
    grid-template-columns: repeat(4, 1fr);
  }
  /* line 208, ../../../scss/sports.scss */
  .content .lists .data-grid.cols-7 .box:nth-child(1), .content .lists .data-grid.cols-7 .box:nth-child(7n+1) {
    grid-column: 1 / 3;
  }
  /* line 212, ../../../scss/sports.scss */
  .content .lists .data-grid.cols-7 .box:nth-child(3), .content .lists .data-grid.cols-7 .box:nth-child(7n+3) {
    border-right: none;
  }
}
/* line 222, ../../../scss/sports.scss */
.loading .loader {
  width: 220px;
  margin: 150px auto;
}
